body {
  background-color: #fff6c8;
  color: #B17A16
}

thead {
  color : #B17A16
}

.button-float-right{
  float: right;
}

.xpto {
  align-items: center;
}




.check-disponivel {
  padding: 0px;
}

.check-disponivel input{
  margin-left: 50px;
}


.div-linha {
  display: flex;
  line-height: 20px;
  padding-left: 45px;
  justify-content: left;
  background-color: #fff6c8;
}

.div-linha-detail {
  display: flex;
  line-height: 20px;
  justify-content: left;
  background-color: #fff6c8;
}


.wrapper {
  
  padding-top: 30px;
}

body h3 {
  margin-bottom: 25px;
}

.navbar-brand a {
  color:  #B17A16;
  font-weight: bold;
}

.form-wrapper,
.table-wrapper {
  max-width: 500px;
  margin: 72px auto 0 auto;
}

.table-wrapper {
  max-width: 700px;
}

.edit-link {
  padding: 7px 10px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}

.navbar {
  background-color: #2B8B34;
}

.navbar-right {
  color: #fff6c8 !important;

}


.accordion ,.accordion-card {
    background-color: #2B8B34;
    color: #fff6c8 ;
    font-weight: bold;
}

.accordion-inner {
  background-color: #2B8B34;
  color: #fff6c8 ;  
  font-weight: normal !important;
}





.bg-unavailable {
  background-image: url('images/unavailable.png');
  background-repeat:no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  background-size:20px 20px;   
  width: 20px; 
  height: 20px;
}


.bg-new {
  background-image: url('images/new.png');
  background-repeat:no-repeat;
  background-position-x: 5px;
  background-position-y: center;
  background-size:35px 35px;   
  width: 35px; 
  height: 35px;
}

.bg-Ouro, .bg-Prata, .bg-Bronze, .bg-Diamante {
  background-color: #B17A16  ;
}


.text-left-title{
  font-weight: bold;
  padding-left:40px !important;
  color: #1D2C19 ;
  background-color: #B17A16 ;
}

.text-left2 {
  color: #fff6c8 ;  
  padding-left:40px !important;
}

.table-hover tbody tr.bg-table-title:hover td, .table-hover tbody tr.bg-table-title:hover th {
  background-color: #ce9938;
}

.table-hover tbody tr.row-detail:hover td, .table-hover tbody tr.row-detail:hover th {
  background-color: #fff6c8;
}


.table td{
  border: #847a48   solid 1px;
}

.row-detail {
  color: #2B8B34
}

.color-red {
  color: red
}

.color-green {
  color: green
}

.navbar-brand .nav-link img {
  margin: -10px 0px;
}
